.header {
  padding: 1rem;

  nav ul {
    display: flex;
    list-style-type: none;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
    align-items: center;

    li {
      margin-left: 1rem;
      transition: 0.5s;
      
      a {
        color: $text-color; // Assuming $text-color is white
        display: block;
        position: relative;
        text-decoration: none;
        padding: 0.3rem;
        border-radius: 4px;
        overflow: hidden; // Ensure pseudo-element stays within bounds
        transition: 0.3s ease;

        &:hover {
          color: $background-color; // Change text color to black on hover
          background: $text-color;
        }
      }
    }
  }
}

.homeNavLink {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  border: 1px solid $text-color;
  color: $text-color;
  overflow: hidden;
  background: none !important;
  z-index: 1;
  &::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: $text-color;
    transition: top 0.3s ease;
    z-index: -1; // Make sure the background is behind the text
  }

  &:hover {
    color: $background-color;
    &::before {
      top: 0;
    }
  }
}

@media screen and (max-width: 524px) {
  .header{
    nav ul{
      justify-content: center;
      font-size: 1rem;
      li {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }
    }
  }
}

@media screen and (max-width: 224px) {
  .header{
    nav ul{
      justify-content: center;
      font-size: 0.75rem;
      li {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }
    }
  }
}
