.project {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  padding-bottom: 20vh;
  box-sizing: border-box;
  text-align: left;

  img {
    max-width: 100%;
    border: solid 2px $text-color;
    border-radius: 8px;
    height: auto;
    margin: 20px 0;
  }

  a {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    border: 2px solid $text-color;
    border-radius: 8px;
    text-decoration: none;
    color: $text-color;
  }
}

.projectTopContainer {
  h1 {
    text-align: left;
    font-size: 4rem;
    color: $text-color;
  }
}

.projectContentContainer {
  display: flex;
  flex-direction: row;
  width: 60%;
  img {
    max-width: 40%;
    object-fit: contain;
  }
}

.projectDescriptionContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px 0;
  margin-left: 3%;
  p {
    color: $text-color;
    font-size: 1.5rem;
  }
}
.projectLink {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 1.7rem;

  a {
    position: relative;
    display: inline-block;
    width: 100%;
    color: $text-color;
    overflow: hidden;
    transition: color 0.3s ease;

    &::before {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 100%;
      background: $text-color;
      transition: top 0.3s ease;
      z-index: -1; // Make sure the background is behind the text
    }

    &:hover {
      color: $background-color;
      &::before {
        top: 0;
      }
    }
  }
}


@media screen and (max-width: 1150px) {
  .projectTopContainer{
    h1 {
      font-size: 2.5rem;
    }
    p {
      font-size: 1.53rem;
    }
  }
  .projectLink a {
    font-size: 1.53rem;
  }
  .projectContentContainer { 
    width: 80%;
  }
}

@media screen and (max-height: 580px) {
  .projectTopContainer{
    h1 {
      font-size: 2.5rem;
    }
    p {
      font-size: 1.3rem;
    }
  }
  .projectLink a {
    font-size: 1.3rem;
  }
  .projectContentContainer { 
    width: 80%;
  }
}

@media screen and (max-height: 560px) {
  .projectTopContainer{
    h1 {
      font-size: 2.3rem;
    }
    p {
      font-size: 1.1rem;
    }
  }
  .projectLink a {
    font-size: 1.3rem;
  }
  .projectContentContainer { 
    width: 80%;
  }
}

@media screen and (max-width: 730px) {
  .projectTopContainer{
    h1 {
      font-size: 2.10rem;
    }
    p {
      font-size: 1.20rem;
    }
  }
  .projectLink a {
    font-size: 1.20rem;
  }
}

@media screen and (max-width: 626px) {
  .projectTopContainer{
    h1 {
      font-size: 1.7rem;
      width: 93%;
    }
    p {
      font-size: 0.96rem;
    }
  }
  .projectLink a {
    font-size: 0.96rem;
  }
  .projectContentContainer {
    width: 93%;
  }
}

@media screen and (max-width: 524px) {
  .projectTopContainer{
    h1 {
      font-size: 1.3rem;
      text-align:center;
      width: 93%;
    }
    p {
      font-size: 0.80rem;
    }
  }
  .projectLink a {
    font-size: 0.80rem;
  }
  .project {
    padding-left: 0px;
  }
  .projectContentContainer { 
    width: 93%;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 429px) {
  .projectTopContainer{
    h1 {
      font-size: 1.3rem;
    }
    p {
      font-size: 0.85rem;
    }
  }
  .projectLink a {
    font-size: 0.85rem;
  }
}

@media screen and (max-width: 357px) {
  .projectTopContainer{
    h1 {
      font-size: 1.3rem;
    }
    p {
      font-size: 0.75rem;
    }
  }
  .projectLink a {
    font-size: 0.75rem;
  }
}

@media screen and (max-width: 289px) {
  .projectTopContainer{
    h1 {
      font-size: 1.0rem;
    }
    p {
      font-size: 0.65rem;
    }
  }
  .projectLink a {
    font-size: 0.65rem;
  }
}


