.works {
  position: relative;
  height: 100vh;
  overflow: hidden;
  align-items: center;
  justify-content: center;

  .project-container {
    display: flex;
    margin-left:12px;
    width:100%;
    flex-direction: column;
    transition: transform 0.3s ease-in-out;
  }
  
  .project {
    width: 100%;
    display: flex;
  }
}

