.footer {
  margin-left: 18px;
  margin-bottom: 18px;
  margin-right: 18px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: end;
}

.time{
  color: $text-color;
}

.contactLinks {
  nav{
    ul {
      display: flex;
      flex-direction: row;
      align-items: baseline;

      li {
        margin-left: 14px;
        display: flex;

        a {
          display: flex;
          align-items: center;
          color: $text-color;
          font-size: 2rem;
          transition: 0.3s ease;
          padding: 2px;
          border-radius: 5px;
        }
        a:hover {
          color: $background-color;
          background: $text-color;
        }
      }
    }
  }
}



@media screen and (max-width: 626px) {
  .time{
    font-size: 0.8rem;
  }
  .contactLinks{
    nav ul li a {
      font-size: 1.7rem;
    }
  }
}

@media screen and (max-width: 524px) {
  .time{
    font-size: 0.8rem;
  }
  .contactLinks{
    nav ul li a {
      font-size: 1.5rem;
    }
  }
}

@media screen and (max-width: 356px) {
  .time{
    font-size: 0.65rem;
  }
  .contactLinks{
    nav ul li a {
      font-size: 1.2rem;
    }
  }
}
