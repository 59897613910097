.project-nav {
  z-index: 2;
  position: fixed;
  right: 20px;
  margin-right: 5vh;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;

  .circles {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;

    .circle {
      width: 20px;
      height: 20px;
      border: 2px solid $text-color;
      border-radius: 50%;
      margin: 5px 0;
      cursor: pointer;
      transition: 0.3s ease;
      &.active {
        background-color: $text-color;
      }
    }
  }

  .up-arrow,
  .down-arrow {
    background: none;
    border: none;
    color: $background-color;
    font-size: 24px;
    cursor: pointer;
    margin: 10px 0;
    transition: 0.3s ease;
    pointer-events: none;
  }
  .up-arrow.visible, .down-arrow.visible {
    color: $text-color;
    pointer-events: auto;
  }
}

@media screen and (max-width: 730px) {
  .project-nav{
    margin-right: 2.5vh;
  }
}

@media screen and (max-width: 630px) {
  .project-nav {
    .circles {
      .circle, .circle-active {
        height: 15px; width: 15px;
      }
      margin: 0 0;
    }
  }
}

@media screen and (max-width: 524px) {
  .project-nav{
    margin-right: 0vh;
    right: 2.5vh;
  }
}

@media screen and (max-width: 430px) {
  .project-nav {
    .circles {
      .circle, .circle-active {
        height: 10px; width: 10px;
        margin: 4px 0;
      }
      margin: 0 0;
    }
    .down-arrow {
      margin: 5px 0;
    }
    .up-arrow {
      margin: 5px 0;
    }
  }
}
