.about {
  margin-left: 18px;
  margin-right: 18px;
  margin-bottom: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.About-title {
  background: $background-color;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $text-color;
  row-gap: 10px;
  margin-left:12px;

  h1{
    font-size: 6rem;
  }
  
  .About-Text {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
  }

  p{
    font-size: 1.5rem;
    width: 50rem;
    a {
      color: $text-color;
    }
  }

/* reponsive */

  @media screen and (max-width: 1150px) {
    h1 {
      font-size: 4.5rem;
    }
    p {
      font-size: 1.53rem;
    }
  }

  @media screen and (max-width: 730px) {
    h1 {
      font-size: 3.75rem;
    }
    p {
      font-size: 1.20rem;
    }
  }

  @media screen and (max-width: 626px) {
    h1 {
      font-size: 3rem;
    }
    p {
      font-size: 0.96rem;
    }
  }

  @media screen and (max-width: 524px) {
    h1 {
      font-size: 2.2rem;
    }
    p {
      font-size: 0.80rem;
    }
  }

  @media screen and (max-width: 429px) {
    h1 {
      font-size: 2rem;
    }
    p {
      font-size: 0.85rem;
    }
  }

  @media screen and (max-width: 357px) {
    h1 {
      font-size: 1.6rem;
    }
    p {
      font-size: 0.75rem;
    }
  }

  @media screen and (max-width: 289px) {
    h1 {
      font-size: 1.3rem;
    }
    p {
      font-size: 0.65rem;
    }
  }

}
