$primary-color: #3498db;
$secondary-color: #2ecc71;

// grey scale colors
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;

$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

// bg and text colors
$background-color: #111;
$text-color: $gray-200;

// semantic colors
$success-color: #28a745;
$error-color: #dc3545;
$warning-color: #ffc107;
$info-color: #17a2b8;
