*,
*::before,
*::after {
  box-sizing: border-box;
}

.Site {
  background: $background-color;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.Frame {
  background: $background-color;
  display: flex;
  height: 100%;
  flex-direction: column;
  margin: 3vh;
  border: 2px solid $text-color;
  border-radius: 5px;
  box-sizing: border-box;
  justify-content: space-between;
  overflow: hidden;
}

.react-icons {
  vertical-align: middle;
}

p {
  line-height:1.2 !important;
}

@media screen and (max-width: 524px) {
  .Frame {
    margin: 1.5vh;
  }
}
