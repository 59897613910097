.home {
  margin-left: 18px;
  margin-right: 18px;
  margin-bottom: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.Name-title {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $text-color;
  row-gap: 10px;
  margin-left:12px;

  h1{
    font-size: 6rem;
  }
  
  p{
    min-height:2.45rem;
    font-size: 1.93rem;
  }
  .Title-links{
    display: flex;
    column-gap: 16px;
    a{
      display: flex;
      column-gap: 5px;
      font-size: 1.2rem; 
      color: $text-color;
      margin-top:16px;
    }
  }
}

.type::after {
  content: '_';
  animation: cursor 1.1s infinite step-start;
}
 
@keyframes cursor {
  50% {
    opacity: 0;
  }
}

/* RESPONSIVE */

@media screen and (max-width: 1150px) {
  .Name-title {
    h1 {
      font-size: 4.5rem;
    }
    p {
      font-size: 1.45rem;
    }
  }
}

@media screen and (max-width: 730px) {
  .Name-title {
    h1 {
      font-size: 3.75rem;
    }
    p {
      font-size: 1.20rem;
    }
  }
}

@media screen and (max-width: 626px) {
  .Name-title {
    h1 {
      font-size: 3rem;
    }
    p {
      font-size: 0.96rem;
    }
    .Title-links{
      a {
        font-size: 1rem;
      }
    }
  }
}

@media screen and (max-width: 524px) {
  .Name-title {
    h1 {
      font-size: 2.2rem;
    }
    p {
      font-size: 0.80rem;
    }
    .Title-links{
      a {
        font-size: 0.8rem;
      }
    }
  }
}

@media screen and (max-width: 429px) {
  .Name-title {
    h1 {
      font-size: 2rem;
    }
    p {
      font-size: 1.25rem;
      min-height: 3rem;
    }
  }
}

@media screen and (max-width: 357px) {
  .Name-title {
    h1 {
      font-size: 1.6rem;
    }
    p {
      font-size: 1rem;
    }
    .Title-links{
      a {
        font-size: 0.8rem;
      }
    }
  }
}

@media screen and (max-width: 289px) {
  .Name-title {
    h1 {
      font-size: 1.3rem;
    }
    p {
      font-size: 0.8rem;
    }
    .Title-links{
      a {
        font-size: 0.65rem;
      }
    }
  }
}
